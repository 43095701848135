import React, { FC, useState } from 'react';

type props = {
  rate: number;
  handleRate: (rate: number) => void;
};

const RatingComponent: FC<props> = ({ rate , handleRate}) => {
  const [rating, setRating] = useState(rate);
  const [hoverRating, setHoverRating] = useState(0);
  const [disabled, setDisabled] = useState(rating > -1);

  const handleRatingChange = (value: number) => {
    if (disabled) return;
    setRating(value);
    setDisabled(true)
    handleRate(value)
  };

  const handleMouseEnter = (value: number) => {
    if (disabled) return;

    setHoverRating(value);
  };

  const handleMouseLeave = () => {
    if (disabled) return;

    setHoverRating(0);
  };

  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      const starClass = i <= (hoverRating || rating) ? 'bi-star-fill' : 'bi-star';
      stars.push(
        <div
          key={i}
          className={`rating-label me-2 ${i <= rating ? 'checked' : ''}`}
          onClick={() => handleRatingChange(i)}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
        >
          <i className={`bi fs-5 ${starClass}`} style={{ color: i <= (hoverRating || rating) ? 'gold' : 'gray' }}></i>
        </div>
      );
    }

    return stars;
  };

  return <div className="rating">{renderStars()}</div>;
};

export default RatingComponent;
