import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { AdModel, getAd, rateAd } from '../../modules/services/AdService'
import RatingComponent from './RatingComponent'

const adBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const AdPage: FC = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const q = useMemo(() => new URLSearchParams(search), [search]);
    const uuid = q.get('id');

    const [loading, setLoading] = useState(true);
    const [ad, setAd] = useState<AdModel>();

    useEffect(() => {
        if (!uuid) return navigate('/404');;
        getAd(uuid).then((ad) => {
            if (ad === null) {
                // console.log('Ad not found');
                navigate('/404');
                return;
            }
            setAd(ad);
            setLoading(false);
        });
    }, [uuid]);

    const copy = async (t: string) => {
        if (ad) {
            navigator.clipboard.writeText(t)
        }
    }

    const formatDate = () => {
        if (ad) {
            return new Date(ad?.adDetails?.date).toLocaleString('en-UK')
        }
        return '';
    }
    const rate = (i: number, z: number) => {
        if (!ad) return;
        rateAd(ad?.adDetails?.uuid, i, z).then((ad) => {
            console.log('ratead: ',ad);
        })
    }

    return <>
        <PageTitle breadcrumbs={adBreadCrumbs}>Ad Detail Page</PageTitle>

        <div className="row g-5">
            <div className="col-xl-4 col-md-12">
                <div className="card card-custom card-stretch shadow mb-5">
                    <div className="card-header">
                        <h3 className="card-title">Vehicle Details</h3>
                    </div>
                    <div className="card-body">
                        <div className='row mb-lg-7 mb-3 '>
                            <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>VRM</label>
                            <div className='col-lg-8'>
                                <span className='fw-bold fs-4 text-dark'>{ad?.adDetails?.vrm?.toLocaleUpperCase()}</span>
                            </div>
                        </div>
                        <div className='row  mb-lg-7 mb-3'>
                            <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>Vehicle</label>
                            <div className='col-lg-8'>
                                <span className='fw-bold fs-4 text-dark'>{ad?.vehicleDetails?.make} {ad?.vehicleDetails?.model} {ad?.vehicleDetails?.variant}</span>
                            </div>
                        </div>
                        <div className='row  mb-lg-7 mb-3 '>
                            <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>Year</label>
                            <div className='col-lg-8'>
                                <span className='fw-bold fs-4 text-dark'>{ad?.vehicleDetails?.year}</span>
                            </div>
                        </div>
                        <div className='row  mb-lg-7 mb-3 '>
                            <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>Colour</label>
                            <div className='col-lg-8'>
                                <span className='fw-bold fs-4 text-dark'>{ad?.vehicleDetails?.colour}</span>
                            </div>
                        </div>
                        {ad?.adDetails?.specs &&
                            <div className='row mb-lg-7 mb-3 '>
                                <label className='col fw-bold text-muted fs-6 my-auto'>Specification</label>
                                <div className='col-lg-12'>
                                    {ad?.adDetails?.specs.options.map((option: string, i: number) =>
                                        <p className='fw-bold fs-7 my-2' key={'opt_' + i}>- {option}</p>)}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="card-footer">
                        Generated at: <b>{formatDate()}</b>
                    </div>
                </div>
            </div>
            <div className="col-xl-8">

                {ad?.adDetails?.text.map((adCopy, i) =>
                    <div className="card card-custom shadow mb-5" key={'draft_' + i}>
                        <div className="card-header">
                            <h3 className="card-title">Generated Draft {i + 1}</h3>
                            <button
                                type='button'
                                onClick={() => copy(adCopy)}
                                className='btn btn-light-primary btn-sm float-end'
                            >
                                Copy to Clicpboard
                            </button>

                        </div>
                        <div className="card-body">
                            <textarea
                                className='form-control form-control-lg form-control-solid'
                                rows={14}
                                value={adCopy}
                                readOnly
                            ></textarea>
                            {ad?.adDetails?.rating &&
                                <RatingComponent rate={ad?.adDetails?.rating[i]} handleRate={(z) => rate(i, z)} />
                            }
                        </div>
                    </div>
                )}
            </div>


        </div>
    </>
}

export default AdPage
