/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useRef } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Field, ErrorMessage } from 'formik'

const Step1: FC = () => {

  const numberOfKeys = [
    {
      title: '1 Key',
      value: '1'
    },
    {
      title: '2 Keys',
      value: '2'
    },
    {
      title: '3 Keys',
      value: '3'
    }
  ]
  const serviceHistory = [
    {
      title: 'Full Main Dealer',
      value: 'full_main_dealer'
    },
    {
      title: 'Full',
      value: 'full'
    },
    {
      title: 'Partial',
      value: 'partial'
    },
    {
      title: 'None',
      value: 'none'
    }
  ]

  return (
    <div className='w-100'>

      <div className='fv-row'>
        <div className='row'>

          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Enter Vehicle VRM</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='vrm'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='vrm' />
            </div>
          </div>

          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Vehicle Mileage</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='mileage'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='mileage' />
            </div>
          </div>

          <div className="mb-15 fv-row">
            <label className="d-flex align-items-center form-label mb-3">
              Number of Keys
            </label>

            <div className="row mb-2" data-kt-buttons="true">
              {numberOfKeys.map((k_opt, i) => (
                <div className="col" key={"keys_option_" + i}>
                  <Field
                    type="radio"
                    className="btn-check"
                    name="keys"
                    value={k_opt.value}
                    id={'keys_' + i}
                  />
                  <label
                    className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-3"
                    htmlFor={'keys_' + i}
                  >
                    <span className="fw-semibold fs-4">{k_opt.title}</span>
                  </label>
                </div>
              ))}
            </div>

            <div className="text-danger mt-2">
              <ErrorMessage name="keys" />
            </div>
          </div>

          <div className="mb-15 fv-row">
            <label className="d-flex align-items-center form-label mb-3">
              Service History
            </label>

            <div className="row mb-2" data-kt-buttons="true">
              {serviceHistory.map((opt, i) => (
                <div className="col" key={"service_history_option_" + i}>
                  <Field
                    type="radio"
                    className="btn-check"
                    name="serviceHistory"
                    value={opt.value}
                    id={'service_history_' + i}
                  />
                  <label
                    className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-3"
                    htmlFor={'service_history_' + i}
                  >
                    <span className="fw-semibold fs-4">{opt.title}</span>
                  </label>
                </div>
              ))}
            </div>

            <div className="text-danger mt-2">
              <ErrorMessage name="serviceHistory" />
            </div>
          </div>




        </div>
      </div>
    </div>
  )
}

export { Step1 }
