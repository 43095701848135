import {AuthModel} from './_models'
import { REFRESH_URL } from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  
  // JWT refresh token on expire
  // axios.interceptors.response.use(
  //   (response: any) => response,
  //   async (error: any) => {
  //     console.log('error', error);
      
  //     const originalRequest = error.config

  //     if (error.response.status === 401 && !originalRequest._retry) {
  //       console.log('here');
        
  //       originalRequest._retry = true
  //       const auth = getAuth()
  //       if (auth && auth.refreshToken) {
  //         try {
  //           const {data} = await axios.post(REFRESH_URL, {
  //             refreshToken: auth.refreshToken,
  //           })
  //           setAuth(data)
  //           originalRequest.headers.Authorization = `Bearer ${data.token}`
  //           return axios(originalRequest)
  //         } catch (error) {
  //           removeAuth()
  //           window.location.reload()
  //         }
  //       }
  //     }
  //     return Promise.reject(error)
  //   } 
  // )


}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
