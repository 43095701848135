/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { getUserByToken, LoginWithOauthCode } from '../core/_requests'


export function OauthCallback() {
  const navigate = useNavigate()

  const { search } = useLocation();
  const q = useMemo(() => new URLSearchParams(search), [search]);
  const { saveAuth, setCurrentUser } = useAuth()
  const code = q.get('code');



  useEffect(() => {
    if (code) {
      LoginWithOauthCode(code).then((c) => {
        saveAuth(c.data)
        // console.log(c.data);

        getUserByToken(c.data.token).then((u) => {
          // console.log(u.data);
          setCurrentUser(u.data)
          navigate('/', { replace: true })
        })

      })
    }
  }, [code]);


  return (
    <>
      <h1>Loading...</h1>
    </>
  )
}
