import { FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";




const CompleteProfile: FC = () => {
    const navigate = useNavigate()

    return <>
        <div className="d-flex flex-column flex-center flex-row-fluid p-10 pb-lg-20">
            <div className="alert alert-dismissible bg-light-info d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                <div className="text-center">
                    <h5 className="fw-bolder fs-1 mb-5">Missing Profile Data</h5>

                    <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                    <div className="mb-9 fs-4">
                    For best results, please complete your account setup
                    </div>
                    <div className="d-flex flex-center flex-wrap">
                        <a href="#" onClick={() => navigate('/profile/settings')} className="btn btn-info m-2">Profile Setting</a>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default CompleteProfile