// write a globals state context and provider
import React, { createContext, FC, useEffect, useReducer, useState } from 'react';
import { WithChildren } from '../_metronic/helpers';
import { useAuth } from './modules/auth';
import { getHistory, HistoryModel } from './modules/services/HistoryService';
import { getProduct, getPurchase, getSubscriptions, ProductModel, PurchaseModel, SubscriptionModel } from './modules/services/PurchaseService';

export type AppState = {
    history: HistoryModel[];
    products: ProductModel[];
    subscriptions: SubscriptionModel[];
    purchases: PurchaseModel[];
}
export type AppStateProps = {
    state: AppState;
    setState: React.Dispatch<AppState>;
}

const initialState: AppState = {
    history: [],
    products: [],
    purchases: [],
    subscriptions: []
};

const GlobalStateContext = createContext<AppStateProps>({
    state: initialState,
    setState: () => null
});


const GlobalStateProvider: FC<WithChildren> = ({ children }) => {
    const [state, setState] = useState<AppState>(initialState);
    const { currentUser } = useAuth()

    useEffect(() => {
        if (currentUser) {
            getHistory().then(a => setState((state)=> ({ ...state , history: a })))
            getProduct().then(a => setState((state)=> ({ ...state , products: a.reverse() })))
            getSubscriptions().then(a => setState((state)=> ({ ...state , subscriptions: a })))
            getPurchase().then(a => setState((state)=> ({ ...state , purchases: a })))
        }
    }, [currentUser])

    return (
        <GlobalStateContext.Provider value={{ state, setState }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export { GlobalStateContext, GlobalStateProvider };

