import axios from 'axios'
import { API_URL } from '../auth/core/_requests'


const GET_History = `${API_URL}/api/getHistory`

export interface HistoryModel {
    uuid: string;
    date: string;
    vrm: string;
};




export async function getHistory() {
    const history_data = await axios.get<HistoryModel[]>(GET_History);

    if (history_data) {
        return history_data.data;
    }
    return [];
}

export async function countHistoryByMonth(history: HistoryModel[]) {
    const historyByMonth: any = (() => Array.from(Array(12)).reduce((obj, _, i) => (obj[new Date(Date.now() - 864e5 * 30.44 * i).toLocaleString('en-GB', { year: 'numeric', month: 'short' })] = 0, obj), {}))();
    await history.forEach((item) => {
        const month = new Date(item.date).toLocaleString('en-GB', { year: 'numeric',month: 'short' });
        if (historyByMonth[month]) {
            historyByMonth[month] += 1;
        } else {
            historyByMonth[month] = 1;
        }
    });
    return historyByMonth;
}


