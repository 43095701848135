import { FC } from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { Horizontal } from './Horizontal'
import NoCredit from './noCredits'
import CompleteProfile from './completeProfile'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const NewAdPage: FC = () => {
  const { currentUser } = useAuth()

  const checkPage = () => {
    if (currentUser && currentUser.allowance <= currentUser.usedCredits) {
      return <NoCredit />
    } else if (currentUser && !currentUser.services) {
      return <CompleteProfile />
    } else {
      return <Horizontal />
    }
  }

  return <>
    <PageTitle breadcrumbs={wizardsBreadCrumbs}>Generate New Ad</PageTitle>
    {/* {currentUser && currentUser.allowance > currentUser.usedCredits ?
      <Horizontal />
      :
      <NoCredit />
    } */}

    {checkPage()}
  </>
}

export default NewAdPage
