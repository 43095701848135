import React, { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { VehicleModel } from '../../../modules/services/AdService'
import SpecsComponent from './SpecsComponent'

const Step2: FC<{
  vehicle: VehicleModel | null
}> = ({ vehicle }) => {

  return (
    <div className='w-100'>

      <div className='mb-10 fv-row'>
        <div className='row mb-7 '>
          <label className='col-4 fw-bold text-muted fs-6 my-auto'>VRM</label>
          <div className='col-lg-8'>
            <span className='fw-light fs-3 text-dark'>{vehicle?.vrm?.toLocaleUpperCase()}</span>
          </div>
          <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>Vehicle</label>
          <div className='col-lg-8'>
            <span className='fw-light fs-3 text-dark'>{vehicle?.make} {vehicle?.model} {vehicle?.variant}</span>
          </div>
          <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>Year</label>
          <div className='col-lg-8'>
            <span className='fw-light fs-3 text-dark'>{vehicle?.year}</span>
          </div>
          <label className='col-lg-4 fw-bold text-muted fs-6 my-auto'>Colour</label>
          <div className='col-lg-8'>
            <span className='fw-light fs-3 text-dark'>{vehicle?.colour}</span>
          </div>
        </div>

        {vehicle?.specs ?
          <div className='row'>
            <label className='col-lg-12 fw-bold text-muted fs-6 my-auto'>Please select up to 8 top features of this vehicle</label>
            <SpecsComponent vehicle={vehicle} />
          </div>
          :
          <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column px-10 px-lg-20 mb-10">

            <div className="text-center">
              <h5 className="fw-bolder fs-1 my-5">Oops...</h5>

              <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

              <div className="mb-9 fs-4">
                Unfortunately we can not provide the specification for this vehicle. <br/> 
                You have not been charged a credit for this look up, sorry for any inconvenience.
              </div>
            </div>
          </div>
        }

      </div>

    </div>
  )
}

export { Step2 }
