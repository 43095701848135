import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { KTSVG } from '../../../_metronic/helpers'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'
import { AdResponseModel, generateAd, getVehicleDetails, VehicleModel } from '../../modules/services/AdService'
import { GlobalStateContext } from '../../AppState'
import { getHistory } from '../../modules/services/HistoryService'
import { useAuth } from '../../modules/auth'
import { useNavigate } from 'react-router-dom'

const Horizontal: FC = () => {
  const navigate = useNavigate()

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isShowControl, setShowControl] = useState(true)

  const [vehicleDetails, setVehicleDetails] = useState<VehicleModel | null>(null)
  const [adDetails, setAdDetails] = useState<AdResponseModel | null>(null)

  const { state, setState } = useContext(GlobalStateContext);
  const { currentUser, setCurrentUser } = useAuth()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    // console.log("step: ", stepper.current.currentStepIndex);

    switch (stepper.current.currentStepIndex) {
      case 1:
        setLoading(true)
        const v = await getVehicleDetails(values.vrm)
        v.vrm = values.vrm

        setVehicleDetails(v)

        setSubmitButton(true)
        stepper.current.goNext()
        setLoading(false)
        break;

      case 2:
        setLoading(true)

        const ad = await generateAd(values, vehicleDetails?.specs)
        setAdDetails(ad)
        getHistory().then(a => setState({ ...state, history: a }))

        if (currentUser && vehicleDetails?.specs) {
          setCurrentUser({ ...currentUser, usedCredits: currentUser.usedCredits + 1 })
        }

        navigate("/ad?id=" + ad.uuid)

        // stepper.current.goNext()
        setLoading(false)
        setShowControl(false)

        break;
      case 3:

        break;

      default:
        setShowControl(true)
        setSubmitButton(false)
        stepper.current.goto(1)
        actions.resetForm()
        break;
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Vehicle Detail</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Confirmation</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Ad Gen</h3>
            </div>

          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10 ' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content' >
                  <Step1 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 vehicle={vehicleDetails} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 ad={adDetails} />
                </div>


                {isShowControl ?
                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                        disabled={isLoading}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>
                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'
                        disabled={isLoading}>
                        {isLoading &&
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>}
                        {!isLoading &&
                          <span className='indicator-label'>
                            {!isSubmitButton && 'Continue'}
                            {isSubmitButton && 'Submit'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        }
                      </button>
                    </div>
                  </div>
                  : ''}

              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { Horizontal }
