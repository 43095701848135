/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'

const Topbar: FC = () => (
  <div className='d-flex flex-shrink-0'>
    {/* begin::Theme mode */}
    <div className='d-flex align-items-center  ms-3'>
      <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
    </div>
    {/* end::Theme mode */}

    {/* begin::Theme mode */}
    <div className='d-flex align-items-center  ms-3'>
    
    <div className='' id='kt_header_user_menu_toggle'>
      {/* begin::Menu wrapper */}
      <div
        className='cursor-pointer symbol symbol-40px'
        data-kt-menu-trigger='click'
        data-kt-menu-overflow='false'
        data-kt-menu-placement='top-start'
        title='User profile'
      >
        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='avatar' />
      </div>
      {/* end::Menu wrapper */}
      <HeaderUserMenu />
    </div>
    {/* end::User */}
  
    
    
    
    
    </div>
    {/* end::Theme mode */}

    {/* begin::User */}
    </div>
)

export { Topbar }
