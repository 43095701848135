import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../../_metronic/helpers'
import { AdResponseModel } from '../../../modules/services/AdService'

const Step3: FC<{
  ad: AdResponseModel | null
}> = ({ad}) => {

  const adCopy = ad ? ad?.res[0] : '';

  const copy = async () =>{
    navigator.clipboard.writeText(adCopy)
  }

  return (
    <div className='w-100'>
      <div className='pb-10'>
        <h2 className='fw-bolder text-dark'>Business Details</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out.
        </div>
      </div>

      <div className='fv-row mb-10'>
        <Field
          as='textarea'
          name='businessDescription'
          className='form-control form-control-lg form-control-solid'
          rows={25}
          value={adCopy}
        ></Field>
      </div>

      <div className='fv-row mb-10'>
        <button
          type='button'
          onClick={()=> copy()}
          className='btn btn-lg btn-light-primary me-3'
        >
          Copy to Clicpboard
        </button>
      </div>

    </div>
  )
}

export { Step3 }
