import { FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";




const NoCredit: FC = () => {
    const navigate = useNavigate()

    return <>
        <div className="d-flex flex-column flex-center flex-row-fluid p-10 pb-lg-20">
            <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                <div className="text-center">
                    <h5 className="fw-bolder fs-1 my-5">Oops...</h5>

                    <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                    <div className="mb-9 fs-4">
                        You have no credits left. Please purchase more credits to continue.
                    </div>
                    <div className="d-flex flex-center flex-wrap">
                        <a href="#" onClick={() => navigate('/plans')} className="btn btn-danger m-2">Purchase</a>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default NoCredit