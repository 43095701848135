import { FC, useEffect, useState } from "react";
import { VehicleModel } from "../../../modules/services/AdService";
import { KTSVG } from "../../../../_metronic/helpers";




const SpecsComponent: FC<{
    vehicle: VehicleModel | null
}> = ({ vehicle }) => {

    const [specs, setSpecs] = useState<any>({})
    const [selectedSpecs, setSelectedSpecs] = useState<string[]>([])
    const [search, setSearch] = useState<string>('')

    useEffect(() => {
        if (vehicle) {
            setSpecs(vehicle.specs)
            // console.log(vehicle.specs);

        }
    }, [vehicle])

    const changeSpec = (cat: string, subCat: string, i: number) => {
        // console.log(cat,subCat);
        const newSpecs = { ...specs }
        newSpecs[cat][subCat][i].fitment = !newSpecs[cat][subCat][i].fitment
        setSpecs(newSpecs)

        newSpecs[cat][subCat][i].fitment ? setSelectedSpecs([...selectedSpecs, newSpecs[cat][subCat][i].description]) : setSelectedSpecs(selectedSpecs.filter(s => s !== newSpecs[cat][subCat][i].description))
    }

    const checkSearch = (s: string) => {
        return s.toLowerCase().includes(search.toLowerCase())
    }

    return (
        <div>

            <div className='accordion' id='kt_accordion'>
                <div className="input-group my-5">
                    <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search fs-2x"></i>
                    </span>
                    <input className="form-control" type="text" placeholder="Search feature"
                        onChange={(e) => setSearch(e.target.value)} />
                </div>
                {Object.keys(specs).map((k, index) =>
                    <div className='accordion-item' key={'sprect_' + index}>
                        <h2 className='accordion-header' id={'sprect_' + index}>
                            <button
                                className={'accordion-button fs-4 fw-bold collapsed'}
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={'#sprect_' + index + '_body'}
                                aria-expanded='false'
                                aria-controls={'sprect_' + index + '_body'}
                            >
                                {k}
                            </button>
                        </h2>
                        <div
                            id={'sprect_' + index + '_body'}
                            className={'accordion-collapse collapse' + (search.length > 0 ? ' show' : '')}
                            aria-labelledby={'sprect_' + index}
                            data-bs-parent='#kt_accordion'
                        >
                            <div className='accordion-body'>
                                {
                                    Object.keys(specs[k]).map((s: any, i: number) =>
                                        <div className="mb-7" key={'cat_' + i} style={{ display: checkSearch(s) ? 'block' : "none" }}>
                                            <h3 className="fs-6 text-inverse-light">{s}</h3>
                                            {
                                                specs[k][s].map((ss: any, ii: number) =>
                                                    <div className="form-check form-switch form-check-custom form-check-solid mb-2" key={`spec_${i}_${ii}`}>

                                                        <input className="form-check-input" type="checkbox" checked={ss.fitment}
                                                            id="flexSwitchDefault" onChange={(e) => changeSpec(k, s, ii)}
                                                            disabled={selectedSpecs.length > 7 && !ss.fitment} />

                                                        <label className="form-check-label fs-6 fw-bold" >
                                                            {ss.description}
                                                        </label>
                                                        {ss.dvla_fitment &&
                                                            <div title="This option is already fitted by factory">
                                                                <KTSVG className="svg-icon svg-icon-primary" path='/media/icons/duotune/general/gen043.svg' />
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SpecsComponent;