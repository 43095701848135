import * as Yup from 'yup'

export interface ICreateAccount {
  vrm: string
  mileage: number
  keys: string
  serviceHistory: string
}

const createAccountSchemas = [
  Yup.object({
    vrm: Yup.string().required().label('VRM'),
    mileage: Yup.number().required().label('Mileage'),
    keys: Yup.string().required().label('Number of keys'),
    serviceHistory: Yup.string().required().label('Service History'),
  }),
]

const inits: ICreateAccount = {
  vrm: '',
  mileage: 0,
  keys: '',
  serviceHistory: '',
}

export { createAccountSchemas, inits }
